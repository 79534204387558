/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const TermsAndConditions = () => {
  return (
    <Layout>
      <SEO title="Terms" />
      <div className="cms-container">
        <h1 className="cms-container__title">Terms of Use</h1>
        <div className="cms-container__wrapper cms-container__wrapper__terms">
          <p>
            <strong>Last updated: March 1, 2023.</strong>
          </p>
          <p>
            Welcome to MealPal! These Terms of Use (“Terms”) are a contract between you and MealPal New Zealand Limited
            (NZBN: 9429046876904z ) (“MealPal” or “we”) and govern your access to and use of any of the MealPal
            platform, website, mobile application (such as for iPhone or Android) or content, or products and/or
            services made available through MealPal (collectively, the “Site”). Please read these Terms carefully before
            accessing and using the Site.
          </p>
          <ol>
            <li>
              TERMS OF USE
              <ol type="a">
                <li>
                  Acceptance of Terms. By accessing and/or using the Site, you accept, and agree to be bound by these
                  Terms, just as if you had agreed to these Terms in writing. If you do not agree to these Terms, do not
                  use the Site.
                </li>
                <li>
                  Amendment of Terms. MealPal may amend the Terms from time to time in its sole discretion. Unless we
                  provide a delayed effective date, all amendments will be effective upon posting of such updated Terms
                  on the Site. Any significant amendments will be notified to you (either by email or by posting a
                  notification on the Site). Your continued access to or use of the Site after such posting (or
                  effective date, if applicable) constitutes your consent to be bound by the Terms, as amended.
                </li>
                <li>
                  Additional Terms. In addition to these Terms, when using particular plans, offers, products, services
                  or features, you will also be subject to any additional posted terms, guidelines, or rules applicable
                  to such plan, offer, product, service or feature, which may be posted on the Site and modified from
                  time to time. All such additional terms, guidelines or rules are hereby incorporated by reference into
                  the Terms, provided that in the event of any conflict between such additional terms and the Terms, the
                  Terms shall control.
                </li>
                <li>
                  Merchants. Merchants who access or use the Site (including the portal accessible by each Merchant
                  (“Merchant Portal”)) or participate or assist in the provision of content, or products and/or services
                  by MealPal to its customers, will also be bound by these Terms, except to the extent to which the
                  Terms relate specifically to the nature of the relationship between MealPal and its customers that are
                  not Merchants.
                </li>
              </ol>
            </li>
            <li>
              MEALPAL PLATFORM
              <ol type="a">
                <li>
                  MealPal Platform. MealPal is a subscription-based platform that enables MealPal members to reserve
                  meals offered and prepared by restaurants, food service providers, and chefs that partner with MealPal
                  (“Merchants”) and access those Merchants' products and services. MealPal itself is not a restaurant,
                  chef or food service provider and does not own, operate or control any of the restaurants, chefs, or
                  food services or facilities accessible through the Site.
                </li>
                <li>
                  Membership Cycles. Your MealPal membership starts on the date that you sign up for a membership and
                  submit payment for such membership via a valid Payment Method. Unless we otherwise communicate a
                  different time period to you at the time of sign-up (such as a multi-cycle commitment plan), each
                  MealPal cycle is 30 days in length (“Membership Cycle”), and will automatically renew each 30 days
                  until your membership is cancelled or terminated. For example, if you purchase your MealPal membership
                  on April 5, your membership will automatically renew 30 days later, on May 5 (as further explained in
                  paragraph 3(a) below). You must provide us with a current, valid, accepted method of payment (as
                  determined by MealPal from time to time) (“Payment Method”) to use MealPal. We will automatically bill
                  the membership fee to your Payment Method for each cycle until your membership is cancelled or
                  terminated.
                </li>
                <li>
                  Eligible Meals. Each member may reserve and will receive up to 1 meal per weekday (excluding any
                  Saturday, Sunday or public holiday) from a participating Merchant. You may reserve and receive meals
                  from the Merchant of your choice, subject to its availability on our Site. MealPal reserves the right
                  to change from time to time the number of Eligible Meals a member can reserve per cycle, membership
                  plan, geography, Merchant or otherwise. For a current list of public holidays in New Zealand, please
                  visit{' '}
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/nz/faq/`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/nz/faq/`}</a>
                  .
                </li>
                <li>
                  Membership Plans.
                  <ol type="1">
                    <li>
                      <strong>30-Day Plans.</strong> MealPal currently offers members two 30-day subscription plans
                      (“30-Day Plans”). You may purchase a 30-Day Plan for up to 12 meals per 30-day period (“12 Meal
                      30-Day Plan”) or up to 20 meals per 30-day period (“20 Meal 30-Day Plan”).
                    </li>
                    <li>
                      <strong>Flex Plans.</strong> MealPal also offers subscription plans which permit you to purchase a
                      fixed number of meals for a fixed price that can be used at any time (“Flex Plans”). Flex Plans
                      expire one year from date of purchase.
                    </li>
                    <li>
                      <strong>Rollover Plans.</strong> MealPal also offers subscription plans which permit you to
                      purchase a set number of meals that can roll over for a subsequent cycle (“Rollover Plans”).
                      Rollover Plans renew automatically upon the use of all of the meals or upon the end of the 30 day
                      cycle, whichever comes first. At the end of the cycle, unused meals may be rolled over to the next
                      cycle as long as the next cycle is a Rollover plan for at least the same number of meals as the
                      current rollover plan. If a user renews to a rollover plan with a lower number of meals than their
                      prior plan, then the maximum number of unused meals that can rollover is equal to the maximum
                      number of meals on their new rollover plan. Unused meals can only roll over for one cycle.
                    </li>
                    <li>
                      MealPal may also offer a number of other Membership Plans, including special promotional plans or
                      memberships, with differing conditions and limitations. We reserve the right to modify, terminate,
                      supplement, or otherwise amend our offered Membership Plans.
                    </li>
                  </ol>
                </li>
                <li>
                  Merchant and Meal Availability and Allocation. MealPal makes no guarantee or representation as to the
                  availability of particular Merchants, meals, menu choices or other inventory, and you acknowledge and
                  agree that such availability may change over time, including during the course of any given Membership
                  Cycle.
                </li>
                <li>
                  Meal Modifications. MealPal Merchants are unable to make any modifications to the meals that they are
                  offering on the Site. All meals will be prepared as described and you may not request any
                  modifications, substitutions or any other changes to the meals. Requests for modifications,
                  substitutions, or other changes are strictly forbidden and may result in a termination of your MealPal
                  membership.
                </li>
                <li>
                  Meal Ingredients. MealPal makes no guarantee or representation as to the quantity, quality, source,
                  composition, nutritional value, or otherwise, of the ingredients contained in any meal. It is your
                  responsibility to make sure that you can eat all of the ingredients contained in any meal.
                </li>
                <li>
                  Use of MealPal. Your MealPal membership is personal to you and you agree not to create more than one
                  account. Members cannot transfer or gift meals to third parties, including other MealPal members.
                  MealPal may not be used for commercial purposes. To use your MealPal membership you must have access
                  to the Internet and you acknowledge and agree that you will be solely responsible for your own
                  internet access.
                </li>
                <li>
                  We continually update and test various aspects of the Site. We reserve the right to, and by using our
                  service you agree that we may, include you in, or exclude you from, these tests without notice.
                  MealPal makes no warranty or representation as to the constant availability of the Site and does not
                  guarantee a Member’s uninterrupted use or enjoyment of the Site.
                </li>
                <li>
                  In order to pick up your meal, you must have a mobile device that supports the latest version of the
                  MealPal Android App or iOS App. The MealPal Android App or iOS App must be installed on your mobile
                  device, and you must have a working camera. To pick up your reserved meal, you will be required to
                  open the app, log in to your account, and scan the QR code found at the restaurant. Following the
                  scan, you must then show the confirmation success screen to the merchant. MealPal reserves the right
                  to modify this pickup process as required by the restaurant and/or MealPal.
                </li>
                <li>
                  In order to use certain features on the MealPal platform, you are required to have a mobile device.
                  MealPal does not warrant that the Platform will be compatible or interoperable with your mobile
                  device. The Platform requires and uses data access and text messaging capabilities. Carrier rates for
                  data and text messaging may apply, and you are responsible for all of these charges.
                </li>
              </ol>
            </li>
            <li>
              BILLING
              <ol type="a">
                <li>
                  Membership Cycles.
                  <ol type="1">
                    <li>
                      <strong>30-Day Plans.</strong> Your MealPal membership starts on the date that you sign up for a
                      Membership Plan and submit payment via a current, valid, accepted method of payment, which we may
                      update from time to time (your “Payment Method”). Unless we otherwise communicate a different time
                      period to you at the time of sign up (such as a multi-cycle or other type of commitment plan),
                      your initial MealPal Membership Plan cycle (“Membership Cycle”), and any subsequent Membership
                      Cycle, will end on the 30th day or upon use of all of the meals, whichever comes first. Upon the
                      end of each Membership Cycle, your membership will automatically renew for another Membership
                      Cycle, and we will automatically, and without further authorisation from you, bill your Payment
                      Method for another Membership Cycle, unless and until your membership is cancelled or terminated.
                      (For example, if you purchase a MealPal 12 Meal 30-Day Plan subscription on 5 March, and you don’t
                      consume all of the meals, your membership will automatically renew 30 days later on 4 April; if
                      you purchase a MealPal 12 Meal 30-Day Plan subscription on 4 April and you consume the 12th meal
                      on the 25th of April, your membership will automatically renew on 25 April).
                    </li>
                    <li>
                      <strong>Flex Plans.</strong> Your MealPal membership starts on the date that you sign up for a
                      Flex Plan and submit payment via your Payment Method. Your Membership Cycle will end 365 days
                      after your purchase or upon use of all of the meals, whichever comes first. Upon the end of each
                      Membership Cycle, your membership will automatically renew for another Membership Cycle, and we
                      will automatically, and without further authorisation from you, bill your Payment Method for
                      another Membership Cycle, unless and until your membership is cancelled or terminated.
                    </li>
                    <li>
                      <strong>Rollover Plans.</strong> Your MealPal membership starts on the date that you sign up for a
                      Rollover Plan and submit payment via your Payment Method. Your Membership Cycle will end 30 days
                      after your purchase or upon use of all of the meals, whichever comes first. Upon the end of each
                      Membership Cycle, your membership will automatically renew for another Membership Cycle, and we
                      will automatically, and without further authorisation from you, bill your Payment Method for
                      another Membership Cycle, unless and until your membership is cancelled or terminated.
                    </li>
                  </ol>
                </li>
                <li>
                  Recurring Billing. Upon starting your MealPal membership and in consideration for your ability to
                  access and use the Site or receive any products or service provided by MealPal, you authorise us to
                  charge you for your initial Membership Cycle and a recurring membership fee every subsequent
                  Membership Cycle at the then current rate, which may change from time to time. You acknowledge that
                  the amount billed each Membership Cycle may vary for reasons that may include differing amounts due to
                  promotional offers and/or changing or adding a plan, and you authorise us to charge your Payment
                  Method for such varying amounts, which may be billed once every 30 days in one or more charges. You
                  also authorise us to charge you any other fees you may incur in connection with your use of the Site,
                  such as any applicable sign-up fee or taxes (including GST), as further explained below. We reserve
                  the right to change the timing of our billing (and if we do, we’ll make adjustments to the amounts we
                  charge, as appropriate). Your renewal date may change due to changes in your membership. We reserve
                  the right to correct any billing errors or mistakes made by MealPal, and to charge you for any
                  previous Membership Cycles or other fees incurred by you for which you were not already billed.
                </li>
                <li>
                  Billing Holds. You may put your Membership Plan on hold (“Billing Hold”) at any time for up to ninety
                  (90) days. For a Billing Hold to be effective for your next Membership Cycle, it must be received at
                  least 7 days prior to the end of your then-current membership Cycle in a 30-Day Plan or before you
                  reserve your final meal. To establish a Billing Hold, i) email{' '}
                  <a href="mailto:hi@mealpal.com">hi@mealpal.com</a> with “Billing Hold” in the subject line; or ii)
                  access “My Account” from the MealPal Menu dropdown. To establish a Billing Hold in My Account, click
                  “Change Plan for Next Cycle”; then “Hold or Cancel My Account”; and then “Hold Account”. While you are
                  on a Billing Hold, you will not be charged, and your Membership Plan will automatically begin again
                  with a new Membership Cycle commencing on the day that you end your Billing Hold. If you go on hold,
                  and are on one of our rollover plans, any unused meals before you go on hold will not rollover to your
                  cycle when you return from hold.
                </li>
                <li>
                  Refunds. Generally, our fees (including the fee for your membership and any other fees) are
                  non-refundable, except that we will provide a refund to members for their current prepaid period in
                  the following circumstances:
                  <ol type="i">
                    <li>
                      if you are cancelling your membership and request a refund within 5 days of your initial purchase;
                      or
                    </li>
                    <li>
                      if your membership is cancelled prior to the end of a period for which you have incurred a charge,
                      due to your relocation, disability or death.
                    </li>
                  </ol>
                  In each case we reserve the right to charge a fee to cover the cost of any meals or other services you
                  may have used, but not paid for, prior to the date of your cancelation. The current rates that will be
                  charged are $16 NZD per meal (including GST but plus any applicable fees). However, such meal fee
                  charges will not exceed the cost of the membership itself. Any additional discounts or refunds that we
                  may choose to provide are provided in our sole discretion and do not entitle you to any future
                  discounts or refunds in the future for similar instances.
                </li>
                <li>
                  Price Changes. We reserve the right to adjust pricing at any time. Unless we expressly communicate
                  otherwise, any price changes to your membership will take effect on the commencement of your next
                  Membership Cycle, subject to reasonable notice communicated through a posting on the Site or such
                  other means as we may deem appropriate from time to time.
                </li>
                <li>
                  Payment Methods. You may edit your Payment Method information by emailing{' '}
                  <a href="mailto:hi@mealpal.com">hi@mealpal.com</a>. If a payment is not successfully settled, due to
                  expiration of a Payment Method, insufficient funds or otherwise, and you do not; contact us with
                  details of your new or valid Payment Method, or cancel your account (see, paragraph (f) below), you
                  acknowledge and agree that you nonetheless will remain responsible for any owing but uncollected
                  amounts and authorise us to continue billing the Payment Method (as it may be updated, including in
                  the event you attempt to create a new account, which may result in a change to your payment billing
                  dates). If we cannot charge your account for any owing but unpaid amounts, we reserve the right, but
                  are not obligated, to terminate your access to our Site or any portion thereof and provide you with
                  notice of your obligation to pay such unpaid amounts which you must then promptly pay, without delay.
                </li>
                <li>
                  Cancellation of Membership. You may cancel your membership at any time. For a cancellation to be
                  effective for your next Membership Cycle, it must be received at least 7 days prior to the end of your
                  then-current Membership Cycle or before you reserve your final meal. To cancel a membership, i) email{' '}
                  <a href="mailto:hi@mealpal.com">hi@mealpal.com</a> with “Cancel Membership” in the subject line; or
                  ii) access “My Account” from the MealPal dropdown. To cancel your membership in My Account, click
                  “Change Plan for Next Cycle”; then “Hold or Cancel My Account”; and then “Cancel Account”. Your
                  Membership Plan will terminate at the end of that Membership Cycle (you cannot cancel a Membership
                  Plan partway through a Membership Cycle), unless you cancel with fewer than 7 days remaining in a
                  cycle in which case your Membership Plan will terminate at the end of the subsequent Membership Cycle.
                  Following any cancellation, you will continue to have access to your subscription through the end of
                  your current prepaid billing period, and must, prior to the end of the then current Membership Cycle,
                  pay us any amounts owing.
                </li>
                <li>
                  Reservation and Cancellation of Meals. As a MealPal member you must reserve and cancel your MealPal
                  meals only through the MealPal website or mobile application. It is a breach of your MealPal
                  membership terms if you cancel, change, or request a meal directly with a Merchant. Meals can be
                  reserved from 5:00pm on the weekday before the meal will be received. All meals must be reserved by
                  10:30am on the weekday when the meal will be received, otherwise you will not be eligible to receive a
                  meal on that day and you acknowledge and agree that you will not be entitled to any refund for meals
                  not received or not ordered in time. There is no dollar value tied to each individual meal and meals
                  not used, do not rollover to future Membership Cycles, unless otherwise stated by the plan type
                  (defined in Section 3(a) above). If you cancel a meal before 10:30am on the weekday the meal would
                  have been received, you will have the opportunity to reserve another meal before 1:30pm on that
                  weekday. If you cancel a meal after 10:30am on the weekday the meal is to be received, that meal will
                  count toward your total meals allocation for your current Membership Cycle.
                </li>
                <li>
                  Membership Changes. You may change your current Membership Plan to a different Membership Plan by i)
                  emailing <a href="mailto:hi@mealpal.com">hi@mealpal.com</a> with “Change Membership” in subject line;
                  or ii) accessing “My Account” from the MealPal Menu dropdown. To change your Membership Plan in My
                  Account, click “Change Plan for Next Cycle” and then select from one of the available options.
                  Requests to change your membership plan must be received at least 7 days prior to the commencement of
                  your next Membership Cycle (i.e., if your next billing date is 30 September, you must notify MealPal
                  that you want to change your membership plan by 23 September). Plans can only be changed once per
                  Membership Cycle. If you change your plan from a “12 Meal 30-Day Plan” to “20 Meal 30-Day Plan”, there
                  is no fee to return to “12 Meal 30-Day Plan” on and from the next Membership Cycle.
                </li>
                <li>
                  Trials. From time to time we may offer a trial membership that includes standard access to the Site
                  during the trial period. Unless otherwise communicated, a trial begins at the moment of sign-up and
                  ends at 11:59pm on the last weekday of the trial (for a one-week trial, this would be the same weekday
                  of the following week). Each trial membership automatically will convert to a regular membership and
                  price unless cancelled by 12pm on the weekday before the last day of the trial. If you cancel your
                  trial membership and do not convert to a paid membership, then you will not reserve meals to be
                  delivered after the expiry of the trial membership period (even if booking occurred before the end of
                  the applicable trial period). Unless we expressly communicate otherwise, trial memberships are only
                  available to new customers that have never had a MealPal membership before, are not transferable, may
                  not be combined with other offers or redeemed for cash and are void where prohibited.
                </li>
                <li>
                  <p>
                    Gifts and Promotions. From time to time we may make available gift cards for MealPal membership, or
                    other types of promotions or promotional plans (including through the use of promotional codes or
                    those provided as part of a third party promotion). Gift cards, promotions and promotional plans may
                    only be redeemed as described, and may be subject to additional or different terms. Unless otherwise
                    expressly communicated to you in connection with your redemption, gift cards, promotions and
                    promotional plans are (i) only available to new customers that have never had a MealPal account
                    before, and (ii) may only be redeemed once, upon initial sign-up for your first MealPal membership.
                    MealPal has the right to charge your Payment Method the full retail value of any gift card,
                    promotion, or promotional plan that is redeemed by you more than once, or otherwise than as
                    described in the specifics of the gift card, promotion, or promotional plan. For example, if you
                    redeem a promotion offering $40 off a new MealPal Membership Plan at any time after your initial
                    signup for your first MealPal membership, MealPal may charge you $40 in addition to any fees and
                    charges applicable to your membership. Gift cards, promotions, and promotional plans are not
                    transferable, cannot be redeemed for cash, and may not be combined with other offers and are void
                    where prohibited. To be eligible for certain promotions, you must enroll in a 20 Meal 30-Day Plan.
                    In order to receive a promotion, you may be required to provide and verify your cell phone number.
                    The cell phone number you verify must be a unique cell phone number that is not associated with
                    another account in order to receive the promotion. We may also review any other aspects of your
                    account to determine if it is a new customer account or not, and the determination is at MealPal’s
                    discretion. You acknowledge and agree that MealPal is under no obligation to accept a gift card and
                    reserves the right in its sole discretion to void or withdraw any promotion at any time.
                  </p>
                  <p>
                    From time to time we may also make available promotions for referring others to the MealPal service.
                    All referrals require successful validation by MealPal to confirm eligibility. Confirmation of
                    eligibility may include (but is not limited to) validation of email, phone number, credit card,
                    name, device ID, and other customer attributes. If a referral does not reserve a meal within the
                    first 30 days of their membership, they will not be counted as a referral. Final eligibility and
                    qualification for all referrals is at the sole discretion of MealPal
                  </p>
                  <p>
                    If you have created multiple accounts using different email addresses and/or associated the same
                    phone number with multiple email accounts, it is possible that you will receive a marketing
                    promotion that you are not eligible for. As noted in section 2N, it is a violation of these terms to
                    create multiple MealPal accounts.
                  </p>
                  <p>Final eligibility for all marketing promotions is at the sole discretion of MealPal.</p>
                </li>
                <li>
                  Taxes and fees. You acknowledge and agree that taxes (including GST) and fees are charged on all
                  MealPal plans in order to cover the fees and taxes MealPal is required to pay to our Merchants and
                  other third parties.
                </li>
                <li>
                  Public Holidays. You acknowledge that MealPal may not be able to provide meals on public holidays, and
                  the occurrence of a public holiday in any Membership Cycle will not affect the fees paid by you for
                  your membership and you will not receive a refund for any other compensation (financial or otherwise)
                  for the non-availability of meals on any public holiday. For a current list of public holidays in New
                  Zealand, please visit{' '}
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/nz/faq/`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/nz/faq/`}</a>
                </li>
              </ol>
            </li>
            <li>
              OTHER FEES
              <ol type="a">
                <li>
                  Fees We Charge. Your membership fee covers your access to eligible meals as explained above. We
                  reserve the right to change the policy regarding when we charge fees, to introduce additional fees
                  (such as (without limitation) a sign-up fee, late cancellation fee, or missed meal pick-up fee) and to
                  change the amount of any such fees at any time. Additionally, from time to time, we may allow you to
                  purchase (though MealPal is under no obligation to offer) additional meals, products or services
                  through the Site. If you choose to purchase any of these offerings, you will be responsible to pay the
                  applicable fees in addition to your membership fee.
                </li>
                <li>
                  Third Party Fees for Using MealPal. You are also responsible for all third party charges and fees
                  associated with connecting and using the Site, including internet service provider fees, telephone and
                  computer equipment charges, GST and any other costs, charges or fees which apply to your access to the
                  Site.
                </li>
              </ol>
            </li>
            <li>
              TERMINATION OR MODIFICATION BY MEALPAL
              <ol type="a">
                <li>
                  Termination or Modification. You understand and agree that, at any time and without prior notice
                  MealPal may:
                  <ol type="i">
                    <li>
                      terminate, cancel, deactivate and/or suspend your membership, account, any meals reserved, or your
                      access to or use of the Site (or any portion thereof) and/or
                    </li>
                    <li>
                      discontinue, modify or alter any aspect, feature or policy of the Site or your membership. This
                      includes the right to terminate or modify any membership prior to the end of any Membership Cycle.
                      Upon any termination, we may immediately deactivate your account, remove and delete all related
                      information and/or bar any further access to your account, account information and the Site. Upon
                      any such termination by us without cause, as your sole recourse, we will issue you a refund of the
                      fees for the then current Membership Cycle, less any fees or costs owing to MealPal for meals or
                      services already used during that Membership Cycle.
                    </li>
                  </ol>
                </li>
                <li>
                  If we determine that you have violated these Terms or otherwise engaged in illegal or improper use of
                  the Site as prohibited by these Terms, you will not be entitled to any refund. You agree that MealPal
                  will not be liable to you or any third party for any termination or modification to the service
                  regardless of the reason for such termination or modification.
                </li>
                <li>
                  Subject to your rights under the Consumer Guarantees Act 1993, you acknowledge that your only right or
                  remedy with respect to any dissatisfaction with any modification or discontinuation of service made by
                  us is to cancel or terminate your membership. However such right or remedy does not relieve you from
                  any obligations under these Terms related to payment of any amounts owing to MealPal.
                </li>
                <li>
                  Infringing or Fraudulent Activity. MealPal does not permit infringement of any of the intellectual
                  property rights of any person and reserves the right to terminate access to the Site and remove all
                  content submitted by any persons who are suspected or found to be infringing the intellectual property
                  rights of any person or who are causing, or may cause MealPal to do the same. Any suspected
                  fraudulent, abusive, or illegal activity that MealPal considers grounds for termination of your use of
                  the Site may be referred to appropriate law enforcement authorities. These remedies are in addition to
                  any other remedies MealPal may have at law, in equity, under statute or otherwise.
                </li>
              </ol>
            </li>
            <li>
              ELIGIBILITY; REGISTRATION INFORMATION AND PASSWORD; SITE ACCESS
              <ol type="a">
                <li>
                  Eligibility Criteria. The availability of all or part of our Site may be limited based on demographic,
                  geographic, or other criteria as we may establish from time to time. You understand and agree we may
                  disallow you from subscribing to MealPal or may terminate your membership at any time based on these
                  criteria. For example, you must be 18 years of age or older to use this Site or to subscribe to a
                  membership.
                </li>
                <li>
                  THESE TERMS ARE ONLY APPLICABLE TO USERS IN NEW ZEALAND AND SEPARATE TERMS APPLY TO USERS IN OTHER
                  JURISDICTIONS. THE SITE IS NOT AVAILABLE TO ANY USERS SUSPENDED OR REMOVED FROM THE SITE BY MEALPAL OR
                  WHO HAVE HAD THEIR MEMBERSHIP CANCELLED OR TERMINATED BY MEALPAL. BY USING THE SITE, YOU REPRESENT
                  THAT YOU ARE A RESIDENT OF NEW ZEALAND WHO HAS NOT BEEN PREVIOUSLY SUSPENDED OR REMOVED FROM THE SITE.
                  THOSE WHO CHOOSE TO ACCESS THE SITE DO SO AT THEIR OWN INITIATIVE AND ARE RESPONSIBLE FOR COMPLIANCE
                  WITH ALL LOCAL LAWS AND RULES IN RESPECT OF THE SUBJECT MATTER OF THESE TERMS, INCLUDING, WITHOUT
                  LIMITATION, RULES ABOUT THE INTERNET, DATA AND DATA SECURITY, EMAIL OR OTHER ELECTRONIC
                  COMMUNICATIONS, INTELLECTUAL PROPERTY, OR PRIVACY.
                </li>
                <li>
                  Subscribing Organisations. If you are using or opening an account on behalf of a company, entity, or
                  organisation (“Subscribing Organisation”), then you:
                  <ol type="i">
                    <li>
                      represent and warrant that you are an authorised representative of that Subscribing Organisation
                      with the authority to bind such organisation to these Terms;
                    </li>
                    <li>agree to be bound by these Terms on behalf of such Subscribing Organisation; and</li>
                    <li>
                      acknowledge and agree that you and the Subscribing Organisation will be jointly and severally
                      liable for any breach of these terms by you or the Subscribing Organisation. You also acknowledge
                      and agree that the Subscribing Organisation will be responsible for any taxes payable by the
                      Subscribing Organisation as a result of making the Subscribing Organisation’s membership/account
                      available to its employees, directors or office holders.
                    </li>
                  </ol>
                </li>
                <li>
                  Account Information. Account registration requires you to submit to MealPal certain personal
                  information, such as your name, address, and mobile phone number. You are permitted to create only one
                  account. You represent that the information you provide to MealPal at registration and at all other
                  times will be true, accurate, current, and complete. You also agree that you will ensure that this
                  information is kept accurate and up-to-date at all times.
                </li>
                <li>
                  When you register, you will be asked to create a password. You are solely responsible for maintaining
                  the confidentiality of your account information and password and for restricting access to your
                  computer and your account, and you agree to accept responsibility for all activities that occur under
                  your account and membership generally.
                </li>
              </ol>
            </li>
            <li>
              PRIVACY
              <p>
                Your privacy is important to MealPal. The MealPal{' '}
                <a href={`${process.env.GATSBY_LANDING_PAGE_URL}/nz/privacy-policy/`} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                is hereby incorporated into these Terms by reference. Please read the Privacy Policy carefully for
                information relating to MealPal’S collection, use, and disclosure of your Personal Information (as
                defined under the Privacy Act 1993). When you make a reservation, you hereby authorise the applicable
                Merchant to access certain information about you, such as (without limitation); your name and email
                address, so it can provide services to you, communicate with you regarding the meal(s) you reserved and
                send you other communication that may be of interest to you such as marketing offers. Please see the{' '}
                <a href={`${process.env.GATSBY_LANDING_PAGE_URL}/nz/privacy-policy/`} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                for more information.
              </p>
            </li>
            <li>
              PROHIBITED CONDUCT
              <ol type="a">
                <li>
                  You promise not to:
                  <ol type="i">
                    <li>harass, threaten, or defraud users, members or staff of MealPal or any Merchant;</li>
                    <li>make unsolicited offers, advertisements, proposals, or send junk mail or “spam” to users;</li>
                    <li>
                      impersonate another person or access another user’s account without that person’s permission;
                    </li>
                    <li>share MealPal passwords with any third party or encourage any other user to do so;</li>
                    <li>
                      permit third parties to use any meals reserved under your own membership, including other members;
                    </li>
                    <li>cancel any MealPal meals directly with a Merchant, rather than through the Site;</li>
                    <li>
                      misrepresent the source, identity, or content of information transmitted via the Site, including
                      deleting, modifying or obscuring the intellectual property or other proprietary rights of MealPal
                      (including any trademarks);
                    </li>
                    <li>
                      upload any material (e.g. virus or any other harmful code) that is damaging to the Site or the
                      computer systems or data of MealPal, or users of the Site;
                    </li>
                    <li>
                      upload any material that is not your own or that you do not have the legal right to licence,
                      provide, distribute, display, or otherwise make available to others; or
                    </li>
                    <li>
                      upload or send to Site users any unsolicited material or communications (including, without
                      limitation; pornography or threatening, embarrassing, hateful, offensive, insulting, defamatory,
                      or otherwise inappropriate content).
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              PROHIBITED USES
              <ol type="a">
                <li>
                  As a condition of your use of the Site, you will not use the Site for any purpose that is unlawful,
                  prohibited, or not contemplated by these Terms. You may not use the Site in any manner that, in our
                  sole discretion, does or could damage, disable, overburden, impair the Site or otherwise interfere
                  with any other party’s use and enjoyment of the Site.
                </li>
                <li>
                  You may not attempt to gain unauthorised access to the Site, or any part of the Site, other accounts,
                  computer systems or networks connected to the Site, or any part of them, or attempt to interfere with
                  the proper working of the Site or any activities conducted on the Site.
                </li>
                <li>
                  You may not remove, circumvent, disable, damage or otherwise interfere with security-related features
                  of the Site, any features that prevent or restrict use or copying of any content accessible through
                  the Site, or any features that enforce limitations on the use of the Site or the content therein.
                </li>
                <li>
                  You may not obtain or attempt to obtain any materials or information not intentionally made available
                  through the Site, through any means. You agree neither to modify, disassemble, decompile,
                  reverse-engineer or obtain the source code or object code in the Site in any manner or form, nor to
                  use any modified versions of the Site, including (without limitation) for the purpose of obtaining
                  unauthorised access to the Site. You acknowledge that the Site may contain robot exclusion headers.
                  You agree that you will not use any robot, spider, scraper, or other automated means to access the
                  Site for any purpose without our express written permission or bypass our robot exclusion headers or
                  other measures we may use to prevent or restrict access to the Site.
                </li>
                <li>
                  MealPal reserves the right to refuse service, terminate accounts, remove or edit content, or cancel
                  orders in its sole discretion.
                </li>
              </ol>
            </li>
            <li>
              USER SUBMISSIONS OF REVIEWS
              <ol type="a">
                <li>
                  General. The Site provides certain features which enable you, us, and other users to submit, post, and
                  share reviews and other content or materials. These submissions may include without limitation
                  identification of restaurants, meals and orders you have placed, text, graphic and pictorial works, or
                  any other content submitted or ordered by you and other users through the Site (“User Submissions”).
                  User Submissions are displayed for informational purposes only and are not controlled by MealPal.
                  MealPal does not and cannot guarantee any anonymity or confidentiality with respect to any User
                  Submissions, and strongly recommends that you think carefully about what you upload to the Site. You
                  understand that all User Submissions are the sole responsibility of the person from whom such User
                  Submission originated. This means that you, and not MealPal, are entirely responsible for all User
                  Submissions that you upload, post, e-mail, transmit, or otherwise make available through the Site, and
                  MealPal disclaims and excludes all liability or responsibility to any person in respect of any content
                  submitted to the Site by any person.
                </li>
                <li>
                  Right to Remove or Edit User Submissions. MealPal makes no representations that it will publish or
                  make available on the Site any User Submissions, and reserves the right, in its sole discretion, to
                  refuse to allow any User Submissions on the Site, or to edit or remove any User Submission at any time
                  with or without notice.
                </li>
                <li>
                  License Granted by You to MealPal. You retain all your ownership rights in original aspects of your
                  User Submissions. By submitting User Submissions to MealPal, you hereby grant MealPal and its parents,
                  subsidiaries, related companies, affiliates, sublicensees, partners, designees, and assignees of the
                  Site (collectively, the “MealPal Licensees”) a worldwide, non-exclusive, royalty-free, perpetual,
                  irrevocable, sublicensable, and transferable license to use, reproduce (including by making mechanical
                  reproductions), distribute, modify, adapt, translate, prepare derivative works of, publicly display,
                  publish, publicly perform, and otherwise exploit your User Submissions and derivatives thereof in
                  connection with the Site and MealPal’s (and its successors’) business activities, including (without
                  limitation), for marketing, promoting, and redistributing part or all of the Site (and derivative
                  works thereof), in any media formats and through any media channels now known or hereafter discovered
                  or developed.
                </li>
                <li>
                  User Submissions Representations and Warranties. You are solely responsible for your own User
                  Submissions and the consequences of posting or publishing them. In connection with User Submissions,
                  you affirm, represent, and warrant that:
                  <ol type="i">
                    <li>
                      you will not submit any User Submissions which are, or are reasonably likely to be considered
                      pornographic, threatening, embarrassing, hateful, offensive, insulting, defamatory, or otherwise
                      inappropriate;
                    </li>
                    <li>
                      you own, or have the necessary licenses, rights, consents, and permissions to use, and authorise
                      MealPal to use all intellectual property rights in and to your User Submissions to enable
                      inclusion and use of your User Submissions in the manner contemplated by MealPal and these Terms,
                      and to grant the rights and license set forth above; and
                    </li>
                    <li>
                      your User Submissions, MealPal’s or any MealPal Licensee’s use of such User Submissions pursuant
                      to these Terms, and MealPal’s or any of MealPal Licensee’s exercise of the license rights set
                      forth above, do not and will not:
                      <ol type="A">
                        <li>
                          infringe, violate, or misappropriate any third-party right, including any intellectual
                          property rights;
                        </li>
                        <li>cause injury to any other person;</li>
                        <li>violate these Terms or any applicable law or regulation; or</li>
                        <li>
                          require obtaining a license from or paying fees or royalties to any third party for the
                          exercise of any rights granted in these Terms, including, by way of example and not
                          limitation, the payment of any royalties to any copyright owners, including any royalties to
                          any agency, collection society, or other entity that administers such rights on behalf of
                          others; and
                        </li>
                      </ol>
                    </li>
                  </ol>
                  you indemnify and hold harmless MealPal, its parents, subsidiaries, related companies and affiliates,
                  and each of their respective officers, directors, members, employees, consultants, contract employees,
                  representatives and agents, and each of their respective successors and assigns and their licensees,
                  sublicensees, partners, designees, and assignees from any loss or damage which results from a claim,
                  action or proceeding brought by any third party in respect of any of the matters outlined in this
                  paragraph.
                </li>
                <li>
                  MealPal may, but is not obligated to, monitor and edit or remove any activity or content, including,
                  but not limited to content, that MealPal determines in its sole discretion to violate or breach these
                  Terms. MealPal takes no responsibility and assumes no liability for any User Submissions.
                </li>
                <li>
                  Inaccurate or Offensive User Submissions. You understand that when using the Site, you may be exposed
                  to User Submissions from a variety of sources and that MealPal does not endorse and is not responsible
                  for the accuracy, usefulness, safety, or intellectual property rights of or relating to such User
                  Submissions. You further understand and acknowledge that you may be exposed to User Submissions that
                  you may consider to be inaccurate, offensive, indecent, or objectionable. YOU AGREE TO WAIVE, AND
                  HEREBY DO WAIVE, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST MEALPAL WITH
                  RESPECT THERETO.
                </li>
                <li>
                  Feedback. If you provide MealPal with any comments, bug reports, feedback, or modifications proposed
                  or suggested by you to the Site (“Feedback”), MealPal shall have the right to use, or not use such
                  Feedback in its sole discretion, including, but not limited to the incorporation of such suggested
                  changes into the Site. You hereby grant MealPal a perpetual, irrevocable, non-exclusive, royalty-free
                  license under all rights necessary to incorporate and use your Feedback for any purpose.
                </li>
              </ol>
            </li>
            <li>
              OWNERSHIP; PROPRIETARY RIGHTS
              <p>
                The Site is owned and operated by MealPal. The visual interfaces, graphics, design, compilation,
                information, computer code, products, software (including any downloadable software), services, and all
                other elements of the Site provided by MealPal (“Materials”) are protected by the copyright, trade
                dress, patent, and trademark laws of Australia, Canada, New Zealand, the Unites States, the United
                Kingdom and other countries, international conventions, and all other relevant intellectual property and
                proprietary rights, and applicable laws. Except for any content uploaded by you, all Materials contained
                on the Site are the copyrighted property of MealPal or its parents, subsidiaries, related companies or
                affiliates and/or third-party licensors. All trademarks, service marks, and trade names are proprietary
                to MealPal or its parents, subsidiaries, related companies, affiliates and/or third-party licensors.
                Except as expressly authorised by MealPal, you agree not to sell, license, distribute, copy, modify,
                publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise
                make unauthorised use of the Materials.
              </p>
            </li>
            <li>
              THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS
              <p>
                The Site may include links or access to other web sites or services (“Linked Sites”) solely as a
                convenience to users. MealPal does not endorse any such Linked Sites or the information, material,
                products, or services contained on or accessible through those Linked Sites. Furthermore, MealPal makes
                no express or implied warranties or representations with regard to any Linked Site or the information,
                material, products, or services that are contained on or accessible through such Linked Sites and
                disclaims any and all liability in respect of the inclusion of any Linked Sites on the Site. ACCESS AND
                USE OF LINKED SITES, INCLUDING THE INFORMATION, MATERIAL, PRODUCTS, AND SERVICES ON LINKED SITES OR
                AVAILABLE THROUGH LINKED SITES, IS SOLELY AT YOUR OWN RISK.
              </p>
              <p>
                Your correspondence or business dealings with, or participation in promotions of, advertisers found on
                or through the Site are solely between you and such advertiser. YOU AGREE THAT MEALPAL WILL NOT BE
                RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS THE RESULT OF ANY SUCH DEALINGS OR
                AS THE RESULT OF THE PRESENCE OF SUCH ADVERTISERS ON THE SITE.
              </p>
            </li>
            <li>
              NOTICE
              <p>
                Except as explicitly stated otherwise, legal notices will be served, with respect to MealPal, on the
                MealPal national registered agent, and, with respect to you, to the email address you provide to MealPal
                during the registration process. Notice will be deemed given 24 hours after email is sent, unless the
                sending party is notified that the email address is invalid. Alternatively, we may give you legal notice
                by mail to the address provided during the registration process. In such case, notice will be deemed
                given three days after the date of mailing.
              </p>
            </li>
            <li>
              DISCLAIMERS; NO WARRANTIES.
              <ol type="a">
                <li>
                  MEALS, AND OTHER NON-MEALPAL PRODUCTS AND SERVICES OFFERED VIA THE SITE ARE OFFERED AND PROVIDED BY
                  THIRD PARTIES, NOT MEALPAL. YOUR COLLECTION AND USE OF THESE MEALS AND YOUR USE OF THESE NON-MEALPAL
                  PRODUCTS AND SERVICES IS SOLELY AT YOUR OWN RISK. IN NO EVENT SHALL MEALPAL BE LIABLE FOR ANY ACT,
                  ERROR OR OMISSION BY ANY THIRD PARTY, INCLUDING, WITHOUT LIMITATION, ANY ACT, ERROR OR OMISSION WHICH
                  ARISES OUT OF, OR IS ANY WAY CONNECTED WITH, A MEMBER’S VISIT TO A MERCHANT, A MEMBER’S USE OF OR
                  CONSUMPTION OF A MEAL, SERVICE, PRODUCT, APPOINTMENT MADE THROUGH THE SITE, OR THE PERFORMANCE OR
                  NON-PERFORMANCE OF ANY THIRD PARTY PROVIDER IN CONNECTION WITH THE SERVICES. MEALPAL IS NOT AN AGENT
                  OF ANY THIRD PARTY PROVIDER.
                </li>
                <li>
                  NOTHING IN THESE TERMS LIMITS OR OTHERWISE EXCLUDES YOUR RIGHTS UNDER THE CONSUMER GUARANTEES ACT
                  1993.
                </li>
                <li>
                  WITHOUT LIMITING THE FOREGOING, MEALPAL DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS:
                  <ol type="i">
                    <li>
                      THAT THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, MATERIALS, OR APPLICATIONS MADE
                      AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
                      DEFECTS WILL BE CORRECTED, OR THAT THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR
                      APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SITE OR THE SERVER THAT MAKES THEM
                      AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL CODE OR COMPONENTS; OR
                    </li>
                    <li>
                      REGARDING THE USE OF THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, MATERIALS, OR
                      APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SITE, IN TERMS OF CORRECTNESS,
                      ACCURACY, RELIABILITY, OR OTHERWISE. ANY MATERIAL OR DATA THAT YOU DOWNLOAD OR OTHERWISE OBTAIN
                      THROUGH THE SITE IS DOWNLOADED (OR OTHERWISE) AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY
                      DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING FROM THE DOWNLOAD (OR OTHERWISE) OF SUCH
                      MATERIAL OR DATA.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              WAIVER AND RELEASE
              <ol type="a">
                <li>
                  You acknowledge and agree that MealPal is not a restaurant or food provider or other service provider
                  and the meals you consume are sourced and prepared by the applicable Merchant and not by MealPal.
                  Although MealPal endeavours to offer inventory that is of high quality, MealPal is not responsible for
                  and does not warrant or represent the quality of any meals or service.
                </li>
              </ol>
            </li>
            <li>
              INDEMNIFICATION; HOLD HARMLESS
              <p>
                You agree to indemnify and hold MealPal, its parent, subsidiaries, related companies, affiliates,
                contractors, employees and agents, and its Merchants, suppliers and partners harmless from any claims,
                losses, damages, liabilities, including attorneys’ fees, arising out of your misuse of the Site,
                violation of these Terms, violation of the rights of any other person or entity, or any breach of the
                foregoing representations, warranties, and covenants.
              </p>
            </li>
            <li>
              LIMITATION OF LIABILITY AND DAMAGES
              <ol type="a">
                <li>
                  TO THE EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES WILL MEALPAL OR ITS PARENT, SUBSIDIARIES,
                  RELATED COMPANIES, AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR MERCHANTS, THIRD-PARTY PARTNERS OR
                  SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES UNDER ANY THEORY
                  OF LIABILITY, WHETHER BASED IN CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE AND PRODUCT LIABILITY),
                  OR OTHERWISE, EVEN IF MEALPAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </li>
                <li>
                  TO THE EXTENT PERMITTED BY LAW, MEALPAL’S LIABILITY TO YOU IS LIMITED TO $50 OR THE AMOUNTS, IF ANY,
                  PAID BY YOU TO MEALPAL UNDER THE TERMS OF YOUR MEMBERSHIP WITH MEALPAL IN THE THREE MONTHS IMMEDIATELY
                  PRIOR TO THE EVENT FIRST GIVING RISE TO THE CLAIM, WHICHEVER IS MORE, REGARDLESS OF WHETHER MEALPAL
                  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER ANY REMEDY FAILS OF ITS
                  ESSENTIAL PURPOSE.
                </li>
              </ol>
            </li>
            <li>
              DISPUTE RESOLUTION
              <ol type="a">
                <li>
                  For the purpose of this clause
                  <ol type="i">
                    <li>
                      “MealPal” means MealPal and its parents, subsidiaries, related bodies corporate and affiliates,
                      and each of their respective officers, directors, employees, and agents;
                    </li>
                    <li>
                      “Dispute” means any dispute, claim, or controversy between you and MealPal regarding any aspect of
                      your relationship with MealPal, whether based in contract, statute, regulation, ordinance, tort
                      (including, but not limited to, fraud, misrepresentation, fraudulent inducement, negligence, gross
                      negligence or reckless behaviour), or any other legal or equitable theory. “Dispute” is to be
                      given the broadest possible meaning that will be enforced.
                    </li>
                  </ol>
                </li>
                <li>
                  A party must not start court proceedings (except proceedings seeking interlocutory relief) unless it
                  has first complied with this clause.
                </li>
                <li>
                  A party claiming that a Dispute has arisen must give the other party notice of the details of the
                  Dispute (“Dispute Notice”).
                </li>
                <li>
                  When a Dispute Notice is given each party agrees to refer the Dispute for mediation by the
                  Arbitrators’ and Mediators’ Institute of New Zealand Inc (“AMINZ”) for resolution in accordance with
                  the AMINZ Mediation Protocol.
                </li>
                <li>
                  If the parties cannot resolve a Dispute in accordance with the process outlined at paragraph (d)
                  above, then each party may commence court proceedings.
                </li>
                <li>
                  If a party breaches this clause in relation to a Dispute, the other party need not comply with this
                  clause in relation to that Dispute.
                </li>
                <li>
                  The parties must continue to perform their respective obligations under these Terms pending the
                  resolution of a Dispute.
                </li>
                <li>Each party must pay its own costs of complying with this clause.</li>
                <li>
                  A mediator appointed in accordance with paragraph (d) above may not consolidate more than one person’s
                  claims, and may not otherwise preside over any form of a className or representative proceeding or
                  claims (such as a className action) unless otherwise agreed between the parties.
                </li>
                <li>
                  If any aspect of this clause is found to be illegal or unenforceable, then the relevant aspect will be
                  severed from this clause and the remainder of the clause will be given full force and effect.
                </li>
                <li>
                  This clause shall survive the termination of your membership with MealPal and your use of the MealPal
                  Site and services.
                </li>
              </ol>
            </li>
            <li>
              MISCELLANEOUS
              <ol type="a">
                <li>
                  Choice of Law; Forum. These Terms shall be governed in all respects by the laws of New Zealand and the
                  parties irrevocably and unconditionally submit to the non-exclusive jurisdiction of the courts of New
                  Zealand.
                </li>
                <li>
                  You agree that any claim or dispute you may have against MealPal must be resolved by a court located
                  in New Zealand, except as otherwise agreed by the parties.
                </li>
                <li>
                  Assignment. We may assign our rights and obligations under these Terms. The Terms will inure to the
                  benefit of our successors, assigns and licensees. For the avoidance of doubt, you may not assign,
                  novate, transfer or otherwise, your rights and/or obligations under these Terms.
                </li>
                <li>
                  Severability. If any provision of these Terms shall be unlawful, void, or for any reason
                  unenforceable, then that provision will be deemed severable from these Terms and will not affect the
                  validity and enforceability of any remaining provisions.
                </li>
                <li>
                  Headings. The heading references herein are for convenience purposes only, do not constitute a part of
                  these Terms, and will not be deemed to limit or affect any of the provisions hereof.
                </li>
                <li>
                  Entire Agreement. These Terms, the Privacy Policy, and any applicable terms or policies incorporated
                  into these Terms by reference (as amended from time to time, as set forth herein), constitute the
                  entire agreement between you and MealPal relating to the subject matter herein.
                </li>
                <li>
                  Claims; Statute of Limitations. TO THE EXTENT PERMITTED BY LAW, YOU AND MEALPAL AGREE THAT ANY CAUSE
                  OF ACTION ARISING OUT OF OR RELATED TO THESE TERMS OR THE SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER
                  THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                </li>
                <li>
                  Disclosures. The services hereunder are offered by MealPal New Zealand Limited (NZBN: 9429046876904z),
                  Level 20, 88 Shortland Street, Auckland Central, Auckland, 1010, NZ and email:{' '}
                  <a href="mailto:hi@mealpal.com">hi@mealpal.com</a>.
                </li>
                <li>
                  Waiver. No waiver of any of these Terms by MealPal is binding unless authorised in writing by an
                  executive officer of MealPal. In the event that MealPal waives a breach of any provision of these
                  Terms, such waiver will not be construed as a continuing waiver of other breaches of the same nature
                  or other provisions of these Terms and will in no manner affect the right of MealPal to enforce the
                  same at a later time.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default TermsAndConditions
